(function() {
  $('.has-tooltip').tooltip();

  $(document).on('click', '[data-confirm]', function(event) {
    var text;
    text = $(this).data('confirm');
    if (!confirm(text)) {
      return event.preventDefault();
    }
  });

  $('.fancybox').fancybox();

  $('.select2').select2();

  $('.has-popover').popover({
    content: function() {
      return $(this).next('.popover-source').html();
    },
    html: true
  });

}).call(this);
