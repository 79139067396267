$ '.has-tooltip'
    .tooltip()

$ document
    .on 'click', '[data-confirm]', (event) ->
        text = $(@).data 'confirm'
        event.preventDefault() unless confirm text

$ '.fancybox'
    .fancybox()

$ '.select2'
    .select2()
    
$ '.has-popover'
    .popover
        content: -> $(@).next('.popover-source').html()
        html: true
